/* global console, Office */

export const getUserAccessToken = async () => {
  try {
    // check if API is supported by the platform
    console.log("isSetSupported: " + Office.context.requirements.isSetSupported("IdentityAPI", "1.3"));
    if (Office.context.requirements.isSetSupported("IdentityAPI", "1.3")) {
      const ssoOptions = {
        allowSignInPrompt: false,
        allowConsentPrompt: false,
        forMSGraphAccess: false, // if true might not work on some platforms, or due to some AAD configurations
      };
      const options = JSON.parse(JSON.stringify(ssoOptions));
      const accessToken = await Office.auth.getAccessToken(options);
      return accessToken;
    } else {
      console.log("IdentityAPI 1.3 is not supported by this platform!");
      return false;
    }
  } catch (exception) {
    if (exception.code === 13003) {
      console.log(
        "SSO is not supported for domain user accounts, only Microsoft 365 Education or work account, or a Microsoft account."
      );
    } else if (exception.code === 13001) {
      console.log("Safari is not supported!");
    } else {
      console.log("Could not get the access token. \nError: " + exception.message);
      console.log(exception);
    }
    return false;
  }
};
